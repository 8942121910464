import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//font-awesome { Didn't later use these, replaced with iconify. But they still work :) }
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { Icon } from '@iconify/vue';



createApp(App)
    .use(store)
    .use(router)
    .use(FontAwesomeIcon)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(Icon)
    .component('Icon', Icon)
    .mount('#root')

library.add(faFacebook, faTwitter, faInstagram, faLinkedin)
