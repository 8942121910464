<template>
    <div
        v-if="waitlistModalStatus"
        class="waitlist_modal"
    >
        <div>

        <img src="@/assets/images/svg/waitlistForm_Bg.svg" alt="background_image">

        <h2 class="header">
            Join the Wait-list
        </h2>

        <button>
            <img
                src="@/assets/images/svg/cancel.svg"
                alt="close"
                @click="closeWaitlistModal"
            >
        </button>

        <form
            @submit.prevent="enlistUser"
        >
            <fieldset>

                <label
                 for="nameField"
                 id="nameField_label"
                >
                    Full Name
                </label>

                <input
                    type="text"
                    name="fullName"
                    id="nameField"
                    placeholder="Obiajulu Anayo"
                    class="regular"
                    required
                    v-model="newUser.fullName"
                >
            </fieldset>

            <fieldset>

                <label
                 for="emailField"
                 id="emailField_label"
                >
                    Email
                </label>

                <input
                    type="email"
                    name="email"
                    id="emailField"
                    placeholder="obi@gmail.com"
                    class="regular"
                    required
                    v-model="newUser.email"
                >
            </fieldset>

            <input
                type="submit"
                value="Submit"
                class="submit-btn regular"
                :class="{disabled: isDisabled}"
                id="waitlistSubmitBtn"
                ref="waitlistSubmitBtn"
            >
        </form>
        </div>
    </div>
</template>

<script>
const querystring = require("querystring");
const axios = require('axios').default;

    export default {
        name: 'waitlistForm',

        data() {
            return {
                newUser: {
                    fullName: "",
                    email: ""
                },

                isDisabled: false
            }
        },

        computed: {
            waitlistModalStatus() {
                return this.$store.state.waitlistModalStatus
            }
        },

        watch: {
            waitlistModalStatus: {
                handler(newValue) {
                    if (newValue == true) {
                        document.body.style.overflow = "hidden";
                    }

                    if (newValue == false) {
                        document.body.style.overflow = "visible";
                        this.isDisabled = false;
                    }
                }
            }
        },

        methods: {
            closeWaitlistModal() {
                this.$store.commit('toogleWaitlistModalStatus');
                this.isDisabled = false;
            },

            async enlistUser() {
                this.isDisabled = true;
                this.$refs.waitlistSubmitBtn.value = "Submitting..";
                this.$refs.disabled = true;

                // send date to excel spreadsheet
               axios
                .post("https://script.google.com/macros/s/AKfycbzSqgM7Hs38PT49M_GkjdWiAyLqF12upHE6eb13JcaY9l5PHVPuLB8mVxJxbZcY8Upykg/exec",
                       querystring.stringify(this.newUser)
                )

                .then( (response) => {
                    //if data registration succeeds, send confirmation mail
                        console.log(response);

                        axios
                        .post(
                            "https://goroots.co/mailer.php", querystring.stringify(this.newUser)
                        )
                        .then( (response) => {
                            console.log(response);
                            this.$store.commit('toogleWaitlistModalStatus');
                            this.$store.commit('toogleWaitlistConModalStatus');
                        })
                        .catch( (error)=> {
                             //If data registeration does not succed, show erros modal
                            console.log(error)
                            this.$store.commit('toogleWaitlistModalStatus');
                            this.$store.commit('toogleWaitlistErrorModalStatus');
                            return;
                        })

                        console.log(querystring.stringify(this.newUser))
                    })

                    .catch( (error)=> {
                        //If data registeration does not succed, show erros modal
                        console.log(error)
                         this.$store.commit('toogleWaitlistModalStatus');
                         this.$store.commit('toogleWaitlistErrorModalStatus');
                    })



            }
        },

    }
</script>
