<template>
  <waitlistForm />
  <waitlistConfrimation />
  <waitlistError />
  <heroSection />
  <featuresSection />
  <presentationSection />
  <pitchSection />
  <workflowSection />
  <bannerSection />
  <footerSection />
</template>

<script>
  // @ is an alias to /src
  import waitlistForm from '@/components/RootsShared/waitlistForm.vue'
  import waitlistConfrimation from '@/components/RootsShared/waitlistComfirmation.vue'
  import waitlistError from '@/components/RootsShared/waitlistError.vue'
  import heroSection from '@/components/RootsHome/hero.vue'
  import featuresSection from '@/components/RootsHome/features.vue'
  import presentationSection from '@/components/RootsHome/presentation.vue'
  import pitchSection from '@/components/RootsHome/pitch.vue'
  import workflowSection from '@/components/RootsHome/workflow.vue'
  import bannerSection from '@/components/RootsHome/banner.vue'
  import footerSection from '@/components/RootsHome/footer.vue'

  export default {

    name: 'RootsHome',

    components: {
      waitlistForm,
      waitlistConfrimation,
      waitlistError,
      heroSection,
      featuresSection,
      presentationSection,
      pitchSection,
      workflowSection,
      bannerSection,
      footerSection
    }
  }
</script>
