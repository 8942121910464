<template>
    <div
        class="waitlist_modal waitlist_mssg_modal"
        v-if="waitlistConModalStatus"
    >
        <div>
            <img src="@/assets/images/svg/waitlistForm_Bg.svg" alt="background_image">

            <h2 class="header">
                You are currently number
            </h2>

            <div class="user_count">
                {{this.userRegNumber}}
            </div>

            <h2 class="header">
                on the waitlist
            </h2>

            <button>
                <img
                    src="@/assets/images/svg/cancel.svg"
                    alt="close"
                    @click="closeWaitlistConModal"
                >
            </button>

            <p class="regular">
                You will be notified via the mail <br />
                provided when we launch
            </p>

            <button @click="shareRoots">
                Share with friends
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "waitlistConfirmation",

        data() {
            return {
                userRegNumber: this.userRegNumber =  Math.floor((Math.random() * 9000) + 800),
                shareData: {
                    title: 'Roots',
                    text: 'Enjoy seamless purchases at amazing discounts from top vendors and easy access to credit on Roots! Join the waitlist and get notified when we launch',
                    url: 'https://goroots.co'
                }
            }
        },

        computed: {
            waitlistConModalStatus() {
                return this.$store.state.waitlistConModalStatus
            }
        },

        watch: {
            waitlistConModalStatus: {
                handler(newValue) {

                    if (newValue == true) {
                        document.body.style.overflow = "hidden";
                    }

                    if (newValue == false) {
                        document.body.style.overflow = "visible";
                    }
                },

                immediate: true,
            }
        },

        methods: {
            closeWaitlistConModal() {
                this.$store.commit('toogleWaitlistConModalStatus');
            },

            shareRoots() {
                navigator.share(this.shareData)
            }
        },
    }
</script>
