<template>
    <div class="hamBurger" @click="callToogle">
        <div>
        </div>

        <div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'hamBurger',

        methods: {
            callToogle() {
                this.$store.commit('toggleMobileNavStatus');
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '/src/assets/styles/screens';
    @import '/src/assets/styles/colors';
    @import '/src/assets/styles/classes';

    .hamBurger {
        width: 30px;
        height: 30px;
        display: none;
        flex-direction: column;
        align-items: end;
        justify-content: center;

        div {
            width: 100%;
            height: 4px;
            background: $primaryText;
            border-radius: 1.5px;

            &:hover {
                cursor: pointer;
            }

            &:first-child {
                margin-bottom: 4px;
            }
            &:nth-child(2) {
                width: 80%;
            }
        }

    }

    @include heroBreak3() {
        .hamBurger {
            display: flex;
        }
    }

</style>
