import { createStore } from 'vuex'

export default createStore({
  state: {
    mobileNavStatus: false,
    waitlistModalStatus: false,
    waitlistConModalStatus: false,
    featuresCardsData: [
      {
        imageSrc: '@/assets/images/discountIcon.svg',
        imageAlt: 'discount_icon',
        title: 'Massive Discounts',
        description: 'From buying groceries to booking flights, you get to enjoy jaw-dropping discounts from choice vendors'
      },

      {
        imageSrc: '@/assets/images/tickIcon.svg',
        imageAlt: 'tick_icon',
        title: 'Loan Accessibility',
        description: 'Get access to low-interest loans at the point of sale and pay at convenience'
      },

      {
        imageSrc: '@/assets/images//activityIcon.svg',
        imageAlt: 'activity_icon',
        title: 'Unlimited Merchants',
        description: 'Get access to numerous merchants from all walk of life from restaurants to airport bookings, hotels, education, travel etc.'
      }
    ]
  },
  getters: {
  },
  mutations: {
    toggleMobileNavStatus(state) {
      if (state.mobileNavStatus == false) {
        state.mobileNavStatus = true
      }
      else{
        state.mobileNavStatus = false
      }
    },

    toogleWaitlistModalStatus(state) {
      if (state.waitlistModalStatus == false) {
        state.waitlistModalStatus = true
      }
      else{
        state.waitlistModalStatus = false
      }
    },

    toogleWaitlistConModalStatus(state) {
      if (state.waitlistConModalStatus == false) {
        state.waitlistConModalStatus = true
      }
      else{
        state.waitlistConModalStatus = false
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
