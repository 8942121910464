<template>
    <div class="pitch">
       <div class="marginBox">
            <h2 class="header">
                Greater Convenience
            </h2>

            <p>
                We offer your customers greater convenience and ease when making payment
            </p>

            <img src="@/assets/images/webp/pitchImage.webp" alt="payment_convinience">
       </div>
    </div>
</template>

<script>
    export default {
        name: 'pitchSection'
    }
</script>
