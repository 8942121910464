<template>
    <ul class="socialLinks">
        <li>
            <a href="">
                <Icon icon="entypo-social:linkedin-with-circle" />
            </a>
        </li>

        <li>
            <a :href="instagram">
                <Icon icon="ant-design:instagram-filled" />
            </a>
        </li>

        <li>
            <a href="">
                <Icon icon="ic:baseline-facebook" />
            </a>
        </li>


        <li>
            <a :href="twitter">
                <Icon icon="bxl:twitter" />
            </a>
        </li>
    </ul>
</template>

<script>

    export default {
        name: 'socialLinks',

        data() {
           return {
             twitter: "https://twitter.com/OfficialRootsng?t=OgCHCvsVHsgP5Jms0Yx2WQ&s=08",
            instagram: "https://instagram.com/rootsng_official?igshid=YmMyMTA2M2Y="
           }
        }
    }
</script>

<style lang="scss" scoped>

</style>