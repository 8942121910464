<template>
    <div class="presentation">
        <div class="marginBox">
            <div class="heroBg">

        </div>

        <div>
            <div>
                <div>
                    <h2>
                        Boost Sales
                    </h2>

                    <p>
                        Roots helps your boots your
                        sales and in turn increases your revenue from our
                        customers purchasing your products.
                    </p>

                </div>
            </div>

            <div>
                <div>
                    <h2>
                        Market Reach
                    </h2>

                    <p>
                        Roots helps your customers
                        with the latest information on your brand.
                        Plus, you get to access our broad range of customers,
                        boosting sales and generating revenues for your brand.
                    </p>

                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'presentationSection'
    }
</script>
