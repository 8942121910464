<template>
    <div
        class="waitlist_modal waitlist_mssg_modal"
        v-if="waitlistErrorModalStatus"
    >
        <div>
            <img src="@/assets/images/svg/waitlistForm_Bg.svg" alt="background_image">

            <div class="user_count">
               Ooops
            </div>

            <h2 class="header">
                Sorry, we might've done something wrong
            </h2>

            <button>
                <img
                    src="@/assets/images/svg/cancel.svg"
                    alt="close"
                    @click="closeWaitlistErrorModal"
                >
            </button>

            <p class="regular">
                Could you retry that <br />

            </p>

             <button @click="goBackToWaitlistModal">
               Retry
            </button>

        </div>
    </div>
</template>

<script>
    export default {
        name: "waitlistError",

        computed: {
            waitlistErrorModalStatus() {
                return this.$store.state.waitlistErrorModalStatus
            }
        },

        watch: {
            waitlistErrorModalStatus: {
                handler(newValue) {

                    if (newValue == true) {
                        document.body.style.overflow = "hidden";
                    }

                    if (newValue == false) {
                        document.body.style.overflow = "visible";
                    }
                },

                immediate: true,
            }
        },

        methods: {
            closeWaitlistErrorModal() {
                this.$store.commit('toogleWaitlistErrorModalStatus');
            },
            goBackToWaitlistModal() {
                this.closeWaitlistErrorModal();
                this.$store.commit('toogleWaitlistModalStatus');
            }
        },
    }
</script>
