<template>
    <section class="features">
        <div class="marginBox">
            <h2 class="header">
                What we have for you
            </h2>

            <div class="feature-cards">
                <div>
                    <img src="@/assets/images/svg/discountIcon.svg" alt="discount_icon">

                    <h4 class="header">
                        Massive Discounts
                    </h4>

                    <p>
                        From buying groceries to booking flights, you get to enjoy jaw-dropping discounts from choice vendors
                    </p>
                </div>

                <div class="header">
                    <img src="@/assets/images/svg/tickIcon.svg" alt="tick_icon">

                    <h4>
                       Loan Accessibility
                    </h4>

                    <p>
                        Get access to low-interest loans at the point of sale and pay at convenience
                    </p>
                </div>

                <div class="header">
                    <img src="@/assets/images/svg/activityIcon.svg" alt="activity_icon">

                    <h4>
                      Unlimited Merchants
                    </h4>

                    <p>
                        Get access to numerous merchants from all walk of life from restaurants to airport bookings, hotels, education, travel etc.
                    </p>
                </div>
            </div>

            <div class="pep-talk">
                <div>
                    <h2 class="header">
                        Make Us the Root of Business
                    </h2>
                </div>

                 <div>
                    <p>
                        Boost your brand visibility with our broad range of customers and increase sales with roots.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'featuresSection',

        computed: {
            featuresCardsData() {
                return this.$store.state.featuresCardsData
            }
        }
    }
</script>
