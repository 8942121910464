<template>
    <section class="banner">
        <div class="marginBox">
            <img src="@/assets/images/webp/banner.webp" alt="download_the_roots_app_playstore_appstore">
        </div>
    </section>
</template>

<script>
    export default {
        name: 'bannerSection'
    }
</script>
