<template>
    <ul>
        <li>
            <router-link to=" "  class=".regular .primary_alt"> For You </router-link>
        </li>
        <li>
            <router-link to="/merchants" class=".regular .primary_alt"> Merchants </router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'mainNav'
    }
</script>

<style lang="scss" scoped>
    @import '/src/assets/styles/screens';
    @import '/src/assets/styles/colors';
    @import '/src/assets/styles/classes';

    .pageNAV {
        ul {
            list-style-type: none;
            @include flexAlign();
            width: max-content;


            li {
                a {
                    display: block;
                    color: hsla(220, 3%, 30%, 1);


                    &:hover {
                        color: $desaturatedBlue;
                    }
                }

                &:nth-child(2) {
                    margin-left: 51px;
                }

            }
        }
    }

    @include heroBreak3() {
    .pageNAV {
        ul {
            display: none;
        }
    }

}
</style>