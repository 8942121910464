<template>
    <section  class="workflow">
        <div class="marginBox">
            <h2 class="header">
                How Roots Works
            </h2>

            <div class="workflow-cards">
                <div>
                    <h3 class="header">
                        QR CODE
                    </h3>


                    <ul class="primary_alt regular">
                        <li>Pay at your convenience with a QR code in any of our merchant stores</li>
                        <li>Use the Roots app to scan the QR code for seamless payment</li>
                        <li>Get notified of your order</li>
                    </ul>

                    <img src="@/assets/images/webp/cardImage.webp" alt="pay_with_roots">
                </div>

                <div>
                    <h3 class="header">
                        INSTORE
                    </h3>

                    <ul class="primary_alt regular">
                        <li>Walk into any of our merchant's stores </li>
                        <li>Have our merchant input your customer ID</li>
                        <li>Get a confirmation request on your Roots App</li>
                        <li>Input your pin or fingerprint for approval</li>
                        <li>Get your order</li>
                    </ul>

                    <img src="@/assets/images/webp/cardImage2.webp" alt="pay_with_roots">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'workflowSection'
    }
</script>

<style lang="scss" scoped>

</style>