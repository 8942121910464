<template>
    <footer>
        <div>
        <div class="pageID">
            <router-link to="/"  class="roots primary_alt">
                <img src="@/assets/images/png/logo.png" alt="roots_logo">
                <div>roots</div>
            </router-link>
        </div>

        <socialLinks />

        <p>
            © 2022 Roots, Inc. All rights reserved
        </p></div>
    </footer>
</template>

<script>
    import socialLinks from '@/components/RootsShared/socialLinks.vue'

    export default {
        name: 'footerSection',

        components: {
            socialLinks,
        },
    }
</script>

<style lang="scss" scoped>

</style>