<template>
    <div class="mobileNAV" v-show="displayStatus">
        <mainNav />
    </div>
</template>

<script>

    import mainNav from './mainNav.vue'

    export default {
        name: 'mobileNav',

        components: {
            mainNav
        },

        computed: {
            displayStatus() {
                return this.$store.state.mobileNavStatus
            }
        },
    }
</script>

<style lang="scss" >
    @import '/src/assets/styles/screens';
    @import '/src/assets/styles/colors';
    @import '/src/assets/styles/classes';

    .mobileNAV {
        display: none;
        width: 200px;
        position: absolute;
        background: white;
        right: 12%;
        top: 100px;

        ul {
            width: 100%;
            list-style-type: none;
            padding:  20px;
            border-radius: 10px;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.201);

            li {
                a {
                    display: block;
                    color: $grey;
                    padding: 13px 0px 13px 16px;
                    border-radius: 10px;

                    &:first-child {
                        margin-bottom: 5px
                    }

                    &:hover {
                        background: $brightBlue;
                        color: white;
                    }

                }
            }
        }
    }

     @include heroBreak3() {
        .mobileNAV {
            display: flex;
        }
    }

</style>