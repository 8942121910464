<template>
  <section class="hero">

    <div>
      <topHeader />
      <div>
        <h1 class="header">
          Seamless
          <span>Purchases</span>,
          Easy Credit Accessibility
        </h1>

        <p class="regular header">
          We believe in a viable credit system.
          Enjoy seamless purchases at amazing discounts from top vendors.
        </p>

        <button
          @click="showWaitlistModal"
          class="regular primary_btn"
        >
          Join the waitlist
        </button>
      </div>
    </div>

    <div>
      <img src="@/assets/images/webp/appShot.webp" alt="app-screen">
      <div class="heroBg"></div>
    </div>

  </section>
</template>

<script>
  import topHeader from '../RootsShared/topHeader.vue'

  export default {
    name: 'heroSection',

    components: {
      topHeader
    },

    methods: {
      showWaitlistModal() {
          this.$store.commit('toogleWaitlistModalStatus')
      }
    },
  }
</script>
