<template>
    <div class="topHeader">
        <div class="pageID">
            <router-link to="/"  class="roots primary_alt">
                <img src="@/assets/images/png/logo.png" alt="roots_logo"> <span>roots</span>
            </router-link>
        </div>

        <nav class="pageNAV ">
            <mainNav />

            <hamBurger />
        </nav>
    </div>

    <mobileNav />
</template>

<script>

import hamBurger from './hamBurger.vue'
import mainNav from './mainNav.vue'
import mobileNav from './mobileNav.vue'

    export default {
        name: 'topHeader',

        components:{
            hamBurger,
            mainNav,
            mobileNav,
        }
    }
</script>
